// -----------------------------------------------------------
//  CartItemsChangeEvents
// -----------------------------------------------------------
const CartItemsChangeEvents = {
  LOADED: 'cart-item-change-event-loaded',

  QUANTITY_CHANGE: 'cart-item-change-event-quantity-change',
  QUANTITY_CHANGED: 'cart-item-change-event-quantity-changed',

  REMOVE_PRINT: 'cart-item-change-event-remove-print',
  UNREMOVE_PRINT: 'cart-item-change-event-unremove-print',

  REMOVE_PRINTS: 'cart-item-change-event-remove-prints',
  REMOVED_PRINTS: 'cart-item-change-event-removed-prints',
  UNREMOVE_PRINTS: 'cart-item-change-event-unremove-prints',
  UNREMOVED_PRINTS: 'cart-item-change-event-unremoved-prints',

  REMOVE_PRISM: 'cart-item-change-event-remove-prism',
  REMOVED_PRISM: 'cart-item-change-event-removed-prism',
  UNREMOVE_PRISM: 'cart-item-change-event-unremove-prism',
  UNREMOVED_PRISM: 'cart-item-change-event-unremoved-prism',

  ZIP_CODE_CHANGE: 'cart-item-change-event-zip-code',
  ZIP_CODE_CHANGED: 'cart-item-changed-event-zip-code',

  DELIVERY_OPTION_CHANGE: 'cart-item-change-event-delivery-option',
  PICKUP_LOCATION_CHANGE: 'cart-item-change-event-pickup-location',

  UPDATED: 'cart-item-change-event-updated',
};

export const CartItemsAnalyticsEvents = {
  UPDATED: 'cartView',
};

export default CartItemsChangeEvents;
